<template>
  <div>
    <date-time-picker-dialog
      v-model="postDate"
      :is-open="isPostDateDialogOpen"
      :title="$t('informations.postDate')"
      :allow-values-before-date-time="
        durationOption !== 'manual' ? '' : archiveDate
      "
      @close="isPostDateDialogOpen = false"
    />
    <date-time-picker-dialog
      v-model="archiveDate"
      :is-open="isArchiveDateDialogOpen"
      :title="$t('informations.untilDate')"
      :allow-values-after-date-time="postDate"
      @close="isArchiveDateDialogOpen = false"
    />
    <v-row align="center">
      <v-col cols="auto">
        {{ $t('informations.postDate') }}
      </v-col>
      <v-col>
        <v-chip
          v-for="option in Object.keys(postOptions)"
          :key="option"
          :value="option"
          :outlined="option !== postOption"
          color="primary"
          class="ma-1"
          :input-value="postOption === option"
          @click="setPostOption(option)"
        >
          {{ postOptions[option].text }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="auto">
        {{ $t('informations.duration') }}
      </v-col>
      <v-col>
        <v-chip
          v-for="option in Object.keys(durationOptions)"
          :key="option"
          :value="option"
          :outlined="option !== durationOption"
          color="primary"
          class="ma-1"
          :input-value="durationOption === option"
          @click="setDurationOption(option)"
        >
          {{ durationOptions[option].text }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col align="center">
        <span v-html="dateTimeMessage" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  addWeeks,
  addMonths,
  format,
  nextMonday,
  startOfMonth,
} from 'date-fns';
import DateTimePickerDialog from '../../../components/DateTimePicker/DateTimePickerDialog.vue';

export default {
  components: {
    DateTimePickerDialog,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      today: '',
      isInitialEmitNeeded: false,
      latestPostDate: null,
      postOptions: null,
      durationOptions: null,
      isPostDateDialogOpen: false,
      isArchiveDateDialogOpen: false,
    };
  },
  computed: {
    postOption: {
      get() {
        return this.getPostOption();
      },
      set(option) {
        if (option !== 'manual') {
          this.postDate = this.postOptions[option].date;
        }
      },
    },
    durationOption: {
      get() {
        return this.getDurationOption();
      },
      set(option) {
        if (option !== 'manual') {
          this.archiveDate = this.durationOptions[option].date;
        }
      },
    },
    postDate: {
      get() {
        this.isInitialEmitNeeded = this.value.start === '';
        return this.value.start === ''
          ? this.postOptions.today.date
          : this.value.start;
      },
      set(value) {
        this.latestPostDate = value;
        const { durationOption } = this;
        this.createDurationOptions();
        const archiveDate =
          durationOption !== 'manual'
            ? this.durationOptions[durationOption].date
            : this.archiveDate;
        this.emitDateTime(value, archiveDate);
      },
    },
    archiveDate: {
      get() {
        this.isInitialEmitNeeded = this.value.start === '';
        return this.value.end === ''
          ? this.durationOptions.oneWeek.date
          : this.value.end;
      },
      set(value) {
        this.emitDateTime(this.postDate, value);
      },
    },

    dateTimeMessage() {
      let postDate = App.helpers.getDateObject(this.postDate);
      let archiveDate = App.helpers.getDateObject(this.archiveDate);
      postDate = this.$d(postDate, 'eventTime');
      archiveDate = this.$d(archiveDate, 'eventTime');
      return this.$t('informations.dateTimeMessage', {
        postDate,
        archiveDate,
      });
    },
  },

  watch: {
    isInitialEmitNeeded() {
      if (this.isInitialEmitNeeded) {
        this.emitDateTime(this.postDate, this.archiveDate);
        this.isInitialEmitNeeded = false;
      }
    },
  },
  created() {
    let today = App.helpers.getDateObject();
    today = `${format(today, 'yyyy-MM-dd')} 00:00`;
    this.today = App.helpers.getDateObject(today);
    this.createPostOptions();
    this.createDurationOptions();
  },
  methods: {
    setPostOption(option) {
      if (option === 'manual') {
        this.isPostDateDialogOpen = true;
      }
      this.postOption = option;
    },
    setDurationOption(option) {
      if (option === 'manual') {
        this.isArchiveDateDialogOpen = true;
      }
      this.durationOption = option;
    },
    createPostOptions() {
      const nextMon = nextMonday(this.today);
      const firstNextMonth = addMonths(startOfMonth(this.today), 1);
      this.postOptions = {
        today: {
          value: 'today',
          text: this.$t('informations.today'),
          date: format(this.today, 'yyyy-MM-dd HH:mm'),
        },
        nextMonday: {
          value: 'nextMonday',
          text: this.$t('informations.nextMonday'),
          date: format(nextMon, 'yyyy-MM-dd HH:mm'),
        },
        firstNextMonth: {
          value: 'firstNextMonth',
          text: this.$t('informations.firstNextMonth'),
          date: format(firstNextMonth, 'yyyy-MM-dd HH:mm'),
        },
        manual: {
          text: this.$t('informations.manualSelection'),
          date: null,
        },
      };
    },
    createDurationOptions() {
      const postDate =
        this.latestPostDate === null
          ? App.helpers.getDateObject(this.postDate)
          : App.helpers.getDateObject(this.latestPostDate);
      const oneWeek = addWeeks(postDate, 1);
      const oneMonth = addMonths(postDate, 1);
      this.durationOptions = {
        oneWeek: {
          value: 'oneWeek',
          text: this.$t('informations.oneWeek'),
          date: format(oneWeek, 'yyyy-MM-dd HH:mm'),
        },
        oneMonth: {
          value: 'oneMonth',
          text: this.$t('informations.oneMonth'),
          date: format(oneMonth, 'yyyy-MM-dd HH:mm'),
        },
        manual: {
          text: this.$t('informations.manualSelection'),
          date: null,
        },
      };
    },
    getPostOption() {
      const postOptions = Object.values(this.postOptions);
      const option = postOptions.find(
        (option) => option.date === this.postDate,
      );
      return option === undefined ? 'manual' : option.value;
    },
    getDurationOption() {
      const durationOptions = Object.values(this.durationOptions);
      const option = durationOptions.find(
        (option) => option.date === this.archiveDate,
      );
      return option === undefined ? 'manual' : option.value;
    },
    emitDateTime(start, end) {
      this.$emit('input', { start, end });
    },
  },
};
</script>
