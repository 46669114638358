<template>
  <v-dialog :value="isOpen" persistent width="250px">
    <v-card>
      <v-card-title class="pb-0 pr-0 pt-1 pl-2">
        {{ title }}
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <date-time-picker v-model="dateTime" icon="mdi-calendar-clock" />
        <div v-if="!isDateTimeAllowed" align="center">
          <v-alert type="error" dense>
            {{ $t('messages.enterProperDateTimeValue') }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="dateTime === value || !isDateTimeAllowed"
          @click="sendDateTime"
        >
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateTimePicker from './DateTimePicker.vue';

export default {
  components: {
    DateTimePicker,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    allowValuesAfterDateTime: {
      type: String,
      default: '',
    },
    allowValuesBeforeDateTime: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dateTime: '',
    };
  },

  computed: {
    isDateTimeAllowed() {
      return (
        (this.allowValuesAfterDateTime === '' ||
          this.dateTime > this.allowValuesAfterDateTime) &&
        (this.allowValuesBeforeDateTime === '' ||
          this.dateTime < this.allowValuesBeforeDateTime)
      );
    },
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.setDateTime();
      }
    },
  },

  methods: {
    setDateTime() {
      this.dateTime = this.value;
    },
    sendDateTime() {
      this.$emit('input', this.dateTime);
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>
