<template>
  <v-select
    :value="value"
    :items="userDepartmentsThatAreAllowedToPost"
    :label="$t('informations.postedBy')"
    item-text="name"
    item-value="id"
    outlined
    dense
    hide-details
    @input="$emit('input', $event)"
  >
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      userDepartments: 'allDepartments',
    }),
    userDepartmentsThatAreAllowedToPost() {
      return this.userDepartments.filter(
        (dept) => dept.canSendInformation || dept.information_send,
      );
    },
  },
};
</script>
