<template>
  <v-container style="max-width: 1300px">
    <v-row class="px-4 pb-4 headline">
      <v-col cols="12" sm="6" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
        {{ pageTitle }}
      </v-col>
      <v-col class="d-flex justify-end" cols="12" sm="6">
        <v-btn
          v-if="canUserDepartmentsPostInformation"
          color="primary"
          @click="$store.dispatch('openAddEditInformationDialog')"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('informations.addInformation') }}
        </v-btn>
        <v-menu>
          <template #activator="{ on, attrs }">
            <v-btn class="ml-2" icon v-bind="attrs" v-on="on">
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                $store.dispatch(
                  'setIsArchivedInformationsShown',
                  !isArchivedInformationsShown,
                )
              "
            >
              <v-list-item-title>
                {{
                  isArchivedInformationsShown
                    ? $t('informations.viewAll')
                    : $t('informations.viewArchived')
                }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-card>
      <div>
        <v-btn
          :color="
            isFilterByCreatedByMe === false ? 'grey lighten-4' : 'primary'
          "
          class="my-2 ml-3"
          @click="isFilterByCreatedByMe = !isFilterByCreatedByMe"
        >
          <v-icon left>
            {{
              isFilterByCreatedByMe === false
                ? 'mdi-checkbox-blank-outline'
                : 'mdi-check-box-outline'
            }}
          </v-icon>
          {{ $t('informations.createdByMe') }}
        </v-btn>
      </div>
      <information-table :created-by-me="isFilterByCreatedByMe" />
    </v-card>

    <add-edit-information
      :user-department-name="department.name"
      :user-department-id="department.id"
    />

    <display-information />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import AddEditInformation from './components/AddEditInformation.vue';
import DisplayInformation from './components/DisplayInformation.vue';
import InformationTable from './components/InformationTable.vue';

export default {
  components: {
    AddEditInformation,
    DisplayInformation,
    InformationTable,
  },
  data: () => ({
    isFilterByCreatedByMe: false,
  }),
  computed: {
    ...mapGetters('groups', ['departments']),
    ...mapGetters({
      id: 'id',
      departmentId: 'department',
      userDepartments: 'allDepartments',
      isArchivedInformationsShown: 'isArchivedInformationsShown',
    }),
    pageTitle() {
      return this.isArchivedInformationsShown === true
        ? this.$t('informations.archived')
        : this.$t('informations.all');
    },
    department() {
      return this.departments.filter(
        (department) => department.id === this.departmentId,
      )[0];
    },
    canUserDepartmentsPostInformation() {
      if (
        this.userDepartments.some(
          (dept) => dept.information_send || dept.canSendInformation,
        )
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
