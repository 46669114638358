<template>
  <v-row>
    <v-col class="pa-0" cols="auto">
      <v-file-input
        v-model="selectingAttachments"
        class="pt-0 pl-2 pb-1"
        accept=".pdf"
        hide-input
        multiple
        :disabled="numberOfAttachmentsUploading > 0"
        prepend-icon="mdi-paperclip"
        @change="uploadAttachments"
      />
    </v-col>
    <v-col class="pa-0" cols="auto">
      <v-chip-group
        class="pa-0"
        active-class="green lighten-4 black--text"
        column
      >
        <v-chip
          v-for="(attachment, index) in attachments"
          :key="index"
          small
          class="py-1"
          close
          @click="attachmentOpen(attachment)"
          @click:close="removeFromSelected(attachment.id)"
        >
          {{ attachment.actual_name }}
        </v-chip>
      </v-chip-group>
    </v-col>
    <v-col class="pa-1 pt-2" cols="auto">
      <v-chip
        v-for="number in numberOfAttachmentsUploading"
        :key="number"
        small
        class="ml-1"
      >
        <v-btn icon loading />
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data: () => ({
    selectingAttachments: [],
    numberOfAttachmentsUploading: 0,
  }),

  computed: {
    attachments: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    numberOfAttachmentsUploading() {
      this.$emit('attachments-loading', this.numberOfAttachmentsUploading > 0);
    },
  },

  methods: {
    async uploadAttachments() {
      this.numberOfAttachmentsUploading = this.selectingAttachments.length;
      const attachments = [...this.value];
      const attachmentDetails = await this.storeAndGetAttachmentDetails();
      attachmentDetails.forEach((attachment) => {
        attachments.push({
          id: attachment.id,
          actual_name: attachment.actual_name,
          url: attachment.url,
        });
      });
      this.numberOfAttachmentsUploading = 0;
      this.attachments = [...attachments];
      this.selectingAttachments = [];
    },
    async storeAndGetAttachmentDetails() {
      const formData = new FormData();
      this.selectingAttachments.forEach((attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
      try {
        const response = await axios.post(
          '/api/informations/add-attachments/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        return response.data;
      } catch (error) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('informations.attachmentNotUploaded'),
        });
        return [];
      }
    },
    removeFromSelected(id) {
      this.attachments = this.value.filter(
        (attachment) => attachment.id !== id,
      );
    },
    attachmentOpen(attachment) {
      window.open(attachment.url);
    },
  },
};
</script>
