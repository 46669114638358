<template>
  <v-dialog v-model="isDialogOpen" persistent scrollable max-width="1000">
    <v-card>
      <v-card-title>
        {{ informationDialogTitle }}
        <v-spacer />
        <v-btn v-if="copiedInformation !== null" icon @click="pasteInformation">
          <v-icon>mdi-content-paste</v-icon>
        </v-btn>
        <v-btn
          :disabled="isLoading"
          icon
          @click="$store.dispatch('closeAddEditInformationDialog')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-text-field
          ref="subjectField"
          v-model="information.subject"
          class="text-h5 text-info-title"
          hide-details
          :placeholder="$t('informations.enterTitle')"
          :rules="[() => !!information.subject || $t('common.required')]"
          :prepend-inner-icon="information.priority == 1 ? 'mdi-star' : ''"
        />
        <vue-editor
          v-model="information.body"
          class="py-4"
          :placeholder="$t('informations.enterBody')"
          :editor-toolbar="informationBodyToolbar"
        />

        <v-row class="py-2">
          <v-col class="pa-0" cols="auto">
            <v-btn
              class="pt-1"
              :color="information.priority ? 'red' : 'grey darken-2'"
              icon
              @click="information.priority = information.priority ? 0 : 1"
            >
              <v-icon v-if="information.priority">mdi-star</v-icon>
              <v-icon v-else>mdi-star-off</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <attachment-picker
              v-model="information.attachments"
              @attachments-loading="isAttachmentsLoading = $event"
            />
          </v-col>
        </v-row>

        <info-department-selector
          ref="departmentSelector"
          v-model="information.departments"
          class="py-3"
          :choices="allowedDepartments"
        />
        <date-time-selector v-model="postAndArchiveDates" />

        <posted-by-department-selector
          v-model="information.posted_by_department_id"
          class="pt-3"
          style="width: 200px"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          :disabled="isLoading"
          @click="$store.dispatch('closeAddEditInformationDialog')"
        >
          {{ $t('common.cancel') }}
        </v-btn>

        <v-btn
          :loading="isLoading || isAttachmentsLoading"
          :disabled="isLoading || isAttachmentsLoading"
          color="primary"
          class="ml-3"
          @click="checkingCompulsoryFieldInformation"
        >
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { VueEditor } from 'vue2-editor';
import xss from 'xss';
import AttachmentPicker from './AttachmentPicker.vue';
import DateTimeSelector from './DateTimeSelector.vue';
import InfoDepartmentSelector from './InfoDepartmentSelector.vue';
import PostedByDepartmentSelector from './PostedByDepartmentSelector.vue';

export default {
  components: {
    VueEditor,
    AttachmentPicker,
    DateTimeSelector,
    InfoDepartmentSelector,
    PostedByDepartmentSelector,
  },
  props: {
    userDepartmentId: {
      type: Number,
      required: true,
    },

    userDepartmentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false,
      isAttachmentsLoading: false,
      information: this.getDefaultInformation(),
    };
  },
  computed: {
    ...mapGetters('groups', ['departments']),
    ...mapGetters([
      'id',
      'isAddEditInformationDialogOpen',
      'editInformation',
      'copiedInformation',
    ]),
    informationDialogTitle() {
      return this.information.id === 0
        ? this.$t('informations.add')
        : this.$t('informations.edit');
    },
    postAndArchiveDates: {
      get() {
        return {
          start: this.information.start.slice(0, 16),
          end: this.information.end.slice(0, 16),
        };
      },
      set(value) {
        this.information.start = value.start;
        this.information.end = value.end;
      },
    },
    informationBodyToolbar() {
      return [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', { color: [] }, { background: [] }],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ];
    },
    allowedDepartments() {
      return this.departments.filter((department) => department.id !== null);
    },
  },
  watch: {
    isAddEditInformationDialogOpen() {
      if (!this.isAddEditInformationDialogOpen) {
        this.reset();
        return;
      }
      this.isLoading = false;
      this.isDialogOpen = true;
      if (this.editInformation !== null) {
        this.loadEditInformationInForm();
      }
    },
  },
  methods: {
    getDefaultInformation() {
      return {
        id: 0,
        posted_by_department_id: this.userDepartmentId,
        subject: '',
        body: '',
        priority: 0,
        start: '',
        end: '',
        is_visible_to_all: 0,
        departments: [],
        attachments: [],
      };
    },
    loadEditInformationInForm() {
      this.information.id = this.editInformation.id;
      this.information.subject = this.editInformation.subject;
      this.information.body = this.editInformation.body;
      this.information.start = this.editInformation.start;
      this.information.end = this.editInformation.end;
      this.information.priority = this.editInformation.priority;
      this.information.is_visible_to_all =
        this.editInformation.is_visible_to_all;
      this.information.departments =
        this.editInformation.is_visible_to_all === 1
          ? this.allowedDepartments.map((department) => department.id)
          : this.editInformation.departments;
      this.information.posted_by_department_id =
        this.editInformation.posted_by_department_id;
      this.information.attachments = [...this.editInformation.attachments];
      this.$store.dispatch('resetEditInformation');
    },
    pasteInformation() {
      this.information.subject = this.copiedInformation.subject;
      this.information.body = this.copiedInformation.body;
      this.information.start = this.copiedInformation.start;
      this.information.end = this.copiedInformation.end;
      this.information.priority = this.copiedInformation.priority;
      this.information.is_visible_to_all =
        this.copiedInformation.is_visible_to_all;
      this.information.departments =
        this.copiedInformation.is_visible_to_all === 1
          ? this.allowedDepartments.map((department) => department.id)
          : this.copiedInformation.departments;
    },

    async checkingCompulsoryFieldInformation() {
      if (
        this.$refs.subjectField.validate(true) &&
        this.$refs.departmentSelector.validate(true)
      ) {
        this.isLoading = true;
        const information = this.modifyInformationToBeSent();
        if (information.id === 0) {
          await this.postInformation(information);
        } else {
          await this.updateInformation(information);
        }
        this.isLoading = false;
        this.$store.dispatch('closeAddEditInformationDialog');
        this.$store.dispatch('fetchAndSetInformations');
      }
    },

    modifyInformationToBeSent() {
      const { departments, is_visible_to_all } =
        this.getDepartmentsAndVisibilityToSend();
      const attachments = this.information.attachments.map(
        (attachment) => attachment.id,
      );
      return {
        ...this.information,
        body: xss(this.information.body),
        subject: xss(this.information.subject),
        ...{ is_visible_to_all, attachments, departments },
      };
    },
    getDepartmentsAndVisibilityToSend() {
      const infoDepartments = [...this.information.departments];
      const is_visible_to_all =
        this.allowedDepartments.length === infoDepartments.length;
      const departments = is_visible_to_all ? [] : infoDepartments;
      return { departments, is_visible_to_all };
    },

    async postInformation(information) {
      return axios
        .post('/api/informations/', information)
        .then(() => {
          const message = this.$t(`informations.informationCreated`);
          this.$store.dispatch('openSnackbar', { color: 'success', message });
        })
        .catch(() => {
          const message = this.$t(`informations.informationNotCreated`);
          this.$store.dispatch('openSnackbar', { color: 'error', message });
        });
    },

    async updateInformation(information) {
      return axios
        .patch(`/api/informations/${information.id}`, information)
        .then((response) => {
          const message = this.$t(`informations.informationUpdated`);
          this.$store.dispatch('openSnackbar', { color: 'success', message });
        })
        .catch((error) => {
          const message = this.$t(`informations.informationNotUpdated`);
          this.$store.dispatch('openSnackbar', { color: 'error', message });
        });
    },
    reset() {
      this.$refs.subjectField.reset();
      this.$refs.departmentSelector.reset();

      this.information = this.getDefaultInformation();
      this.isDialogOpen = false;
    },
  },
};
</script>

<style>
.text-info-title input {
  text-align: center;
  margin-bottom: 8px;
}
</style>
