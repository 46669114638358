<template>
  <v-data-table
    :loading="isInformationsTableLoading"
    :headers="headers"
    :items="displayedInformations"
    :no-data-text="noDataText"
    :sort-desc="true"
    :mobile-breakpoint="0"
    sort-by="start"
    style="cursor: pointer"
    @click:row="displayDialog"
  >
    <template #[`item.start`]="{ item }">
      <div
        :class="{ 'subtitle-1 font-weight-bold': item.seen !== 1 }"
        style="word-break: keep-all"
      >
        {{ $d(App.helpers.getDateObject(item.start), 'eventTime') }}
      </div>
    </template>

    <template #[`item.subject`]="{ item }">
      <div
        :class="{ 'subtitle-1 font-weight-bold': item.seen !== 1 }"
        style="word-break: keep-all"
      >
        <v-icon v-if="item.priority === 1" class="pr-2" color="red">
          mdi-star
        </v-icon>
        {{ item.subject }}
      </div>
    </template>

    <template #[`item.user_department_name`]="{ item }">
      <div
        :class="{ 'subtitle-1 font-weight-bold': item.seen !== 1 }"
        style="word-break: keep-all"
      >
        {{ item.user_department_name }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    createdByMe: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters([
      'id',
      'informations',
      'isInformationsTableLoading',
      'isArchivedInformationsShown',
      'isErrorInFetchInformations',
    ]),
    headers() {
      return [
        {
          text: this.$t('informations.postedOn'),
          value: 'start',
          width: '30%',
        },
        {
          text: this.$t('informations.subject'),
          value: 'subject',
          width: '50%',
        },
        {
          text: this.$t('informations.postedBy'),
          value: 'user_department_name',
          width: '20%',
        },
      ];
    },
    displayedInformations() {
      const informations = [...this.informations];
      return !this.createdByMe
        ? informations
        : informations.filter((info) => info.user_id === this.id);
    },
    noDataText() {
      return this.isArchivedInformationsShown === true
        ? this.$t('informations.noDataArchived')
        : this.$t('informations.noDataAll');
    },
  },
  watch: {
    isArchivedInformationsShown() {
      this.$store.dispatch('fetchAndSetInformations');
    },
    isErrorInFetchInformations() {
      if (this.isErrorInFetchInformations) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('informations.errorFetchingInformations'),
        });
      }
    },
  },
  created() {
    this.$store.dispatch('setIsArchivedInformationsShown', false);
    this.$store.dispatch('fetchAndSetInformations');
  },
  methods: {
    async displayDialog(details) {
      this.$store.dispatch('fetchAndSetDisplayInformation', details.info_id);
      if (details.seen === 0) {
        await this.$store.dispatch('markInformationAsSeen', details.info_id);
        this.$store.dispatch('fetchAndSetInformations');
      }
    },
  },
};
</script>
