<template>
  <v-autocomplete
    ref="selector"
    v-model="departments"
    :items="choices"
    :search-input.sync="search"
    item-text="name"
    item-value="id"
    hide-details
    :label="$t('informations.departmentsThatCanView')"
    chips
    outlined
    multiple
    :menu-props="{ auto: true }"
    clearable
    :rules="[() => departments.length != 0 || $t('common.required')]"
  >
    <template #selection="{ item, index }">
      <v-chip v-if="isAllDepartmentSelected && index === 0" small>
        {{ $t('common.all') }}
      </v-chip>
      <v-chip v-if="!isAllDepartmentSelected" small>
        {{ item.name }}
      </v-chip>
    </template>
    <template #prepend-item>
      <v-list-item ripple @click="toggleAllDepartmentSelect">
        <v-list-item-action>
          <v-icon :color="isAllDepartmentSelected ? 'primary' : ''">
            {{
              isAllDepartmentSelected
                ? 'mdi-checkbox-marked'
                : 'mdi-checkbox-blank-outline'
            }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('common.all') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    search: null,
  }),

  computed: {
    departments: {
      get() {
        return this.value;
      },
      set(value) {
        this.search = '';
        this.$emit('input', value);
      },
    },

    isAllDepartmentSelected() {
      return this.value.length === this.choices.length;
    },
  },

  methods: {
    toggleAllDepartmentSelect() {
      this.departments = this.isAllDepartmentSelected
        ? []
        : this.choices.map((department) => department.id);
    },
    validate(value) {
      return this.$refs.selector.validate(value);
    },
    reset() {
      this.$refs.selector.reset();
    },
  },
};
</script>
